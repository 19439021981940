import React from 'react';
import { Button } from 'semantic-ui-react';
import './LinkButton.css';

function LinkButton(props)
{
    var { children, ...rest } = props;

    return (
        <Button className='appkit-link-button' {...rest}>{children}</Button>
    );
}

export { LinkButton };