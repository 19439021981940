import React from 'react';
import { Url } from '../app-kit/utils';
import { AppError } from '../app-kit/services';
//import { MediaContextProvider } from '../app-kit/ui';
import { StylesProvider } from '@material-ui/core/styles';
import { ProtectedApp } from '../app-kit/security';
import { UserService } from '../services';
import App from './App.js';


function AppBootstrapper()
{
    var urlComponents = Url(window.location.href).components();
    var hostUri = `${urlComponents.protocol}://${urlComponents.host}`;
    var appUri = (process.env.REACT_APP_BASEURI.toLowerCase() === "window.location" ? hostUri : process.env.REACT_APP_BASEURI);

    return (
        <StylesProvider injectFirst>
            <ProtectedApp
                onConfigure={() => {
                    return {
                        preventAutomaticLogin: false,
                        appUri: appUri,
                        appBasename: process.env.REACT_APP_ROUTER_BASENAME,
                        auth: {
                            clientId: process.env.REACT_APP_ID,
                            authority: process.env.REACT_APP_IDP_AUTHORITY,
                            redirectUri: `${appUri}/auth/callback`, 
                            silentRedirectUri: `${appUri}/auth/silent-callback`,
                            postLogoutRedirectUri: `${appUri}/auth/logout`
                        },
                        cache: {
                            storeAuthStateInCookie: true
                        }
                    }
                }}

                onAppContextInitializing={async (app) => {
                    var userService = new UserService(app.acquireToken);
                    var userAccount = app.getUserAccount();
                    var user = null;

                    try
                    {
                        var requiredScopes = [
                            'https://graph.microsoft.com/User.Read'
                        ];

                        await app.acquireToken({ scopes: requiredScopes });

                        user = await userService.getUserByUserAccount(userAccount);
                        if (user == null)
                        {
                            app.setAppError(AppError.create('Application is unable to process your profile successfully', 403, 
                                `Unable to find your employee record by ${(userAccount != null ? userAccount.username : 'login id')}. This is likely caused by mismatch data between HR data and IDP login`));
                        }
                    }
                    catch (error)
                    {
                        app.setAppError(error);
                    }

                    var appContext = {
                        app: { name: "Modern App Platform" },
                        user: Object.assign({ apps: null }, user),
                        utcFormat: "YYYY-MM-DD HH:mm:ss",
                        loading: false,
                        loadingText: `Please wait, we're performing your action...`
                    };

                    return appContext;
                }}

                render={() => <App /> }
            />
        </StylesProvider>
    );
}


export default AppBootstrapper;