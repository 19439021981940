import moment from 'moment';

function toLocalMoment(utcValue, utcFormat)
{
    var localMoment = null;

    var utcMoment = moment.utc(utcValue, utcFormat);
    if (utcMoment.isValid())
    {
        localMoment = utcMoment.local();
        //console.log("localMoment", localMoment);
    }
    else
    {
        console.error(`Unable to parse utc value ${utcValue} with format ${utcFormat}`)
    }

    return localMoment;
}

function toUtcMoment(localValue, localFormat)
{
    var utcMoment = null;

    var localMoment = moment(localValue, localFormat);
    if (localMoment.isValid())
    {
        utcMoment = localMoment.utc();
        //console.log("utcMoment", utcMoment);
    }
    else
    {
        console.error(`Unable to parse local value ${localValue} with format ${localFormat}`)
    }

    return utcMoment;
}

var DateTimeUtils = {
    toLocalMoment: toLocalMoment,
    toUtcMoment: toUtcMoment
};

export { DateTimeUtils };