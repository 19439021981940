import React from 'react';
import PropTypes from 'prop-types';
import { SplashScreen } from './SplashScreen.js';
import { LinkButton } from './LinkButton.js';
import { Icon } from 'semantic-ui-react';
import './LogoutSplashScreen.css';

function LogoutSplashScreen(props)
{
    var message = <React.Fragment>
        it's a good idea to close all browser windows for security purpose.
    </React.Fragment>;

    if ((typeof (props.loginUri) === 'string') &&
        (props.loginUri.length > 0))
    {
        message = <React.Fragment>
            You can access the application again by clicking <LinkButton onClick={() => window.open(props.loginUri, "_self") }>here</LinkButton>to login.<br />
            Otherwise, it's a good idea to close all browser windows for security purpose.
            </React.Fragment>;
    }

    return (
        <SplashScreen className='appkit-logout-splash-screen' text={{
            title: "You've signed out from your account",
            subtitle: 
                <div>
                    <p className='subtitle'>{message}</p>
                </div>}}
            icon={() => {
                return (
                    <Icon.Group size='massive'>
                        <Icon size='large' name='sign out' color='blue' />
                    </Icon.Group>
                );
            }}
        />
    );
}
LogoutSplashScreen.propTypes = {
    loginUri: PropTypes.string
};

export { LogoutSplashScreen };