import React from 'react';
import PropTypes from 'prop-types';
import { Overlay } from './Overlay.js';
import { Loader } from 'semantic-ui-react';
import './LoadingMask.css';

function LoadingMask(props)
{
    var { text, ...rest } = props;

    return (
        <Overlay {...rest}>
            <Loader active size='massive'>{text}</Loader>
        </Overlay>
    );
}
LoadingMask.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    visible: PropTypes.bool
};

export { LoadingMask };