import React from 'react';
import clsx from 'clsx';
import 'semantic-ui-css/semantic.min.css';
import { PropTypes } from 'prop-types';
import { withRouter } from "react-router";
import { Scrollbars } from 'react-custom-scrollbars';
//import { Responsive } from 'semantic-ui-react';
import { SideMenu } from './SideMenu.js';
import { TopMenu } from './TopMenu';
//import { UserCard } from './UserCard';
import { Overlay } from './Overlay.js'
import { Url } from '../utils';
import { LoadingMask } from './LoadingMask.js';
import { AppList } from './apps-panel/AppList.js';
import './SkeletonUi.css';
import { SlidingPanel } from './SlidingPanel.js';

class SkeletonUi extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleSideMenuMenuButtonClick = this.handleSideMenuMenuButtonClick.bind(this);
        this.handleSideMenuLinkClick = this.handleSideMenuLinkClick.bind(this);
        this.handleSideMenuHitTest = this.handleSideMenuHitTest.bind(this);
        this.handleTopMenuSideMenuLinkClick = this.handleTopMenuSideMenuLinkClick.bind(this);
        this.handleTopMenuLinkClick = this.handleTopMenuLinkClick.bind(this);
        this.handleTopMenuSignOutLinkClick = this.handleTopMenuSignOutLinkClick.bind(this);

        this.handleAppsPanelLinkClick = this.handleAppsPanelLinkClick.bind(this);
        this.handleAppsPanelCollapseButtonClick = this.handleAppsPanelCollapseButtonClick.bind(this);
        this.handleAppsPanelHitTest = this.handleAppsPanelHitTest.bind(this);
        
        this.toggleSideMenu = this.toggleSideMenu.bind(this);
        this.collapseSideMenu = this.collapseSideMenu.bind(this);

        this.state = {
            sideMenuCollapsed: true,
            appsPanelCollapsed: true
        };
    }
    
    render()
    {
        var showSideMenu = false;
        if ((this.props.sideMenu != null) && 
            (this.props.sideMenu.menuItems != null) &&
            (this.props.sideMenu.menuItems instanceof Array) &&
            (this.props.sideMenu.menuItems.length > 0))
        {
            showSideMenu = true;    
        }

        var sideMenu = (
            <React.Fragment>
                {/*Uncomment the following two if you want to show a icon-based side menu on the left when collapsed.*/}
                {/* Side menu (for mobile only) */}
                {/*
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <SideMenu routeBased hideOnCollapsed
                        headerText={this.props.app.name}
                        menuItems={this.props.sideMenu.menuItems}
                        collapsed={this.state.sideMenuCollapsed}
                        onMenuButtonClick={this.handleSideMenuMenuButtonClick}
                        onLinkClick={this.handleSideMenuLinkClick}
                        onHitTest={this.handleSideMenuHitTest}
                        headerBody={() => 
                            <UserCard 
                                collapsed={this.state.sideMenuCollapsed} 
                                name={this.props.user.name} 
                                loginId={this.props.user.loginId} 
                                avatarSize='3em' />
                        }
                    />
                </Responsive>
                */}

                {/* Side menu (for tablet and desktop only) */}
                {/*
                <Responsive minWidth={Responsive.onlyTablet.minWidth} className='left'>
                    <SideMenu routeBased 
                        style={{position: 'relative'}}
                        headerText={this.props.app.name}
                        menuItems={this.props.sideMenu.menuItems}
                        collapsed={this.state.sideMenuCollapsed}
                        onMenuButtonClick={this.handleSideMenuMenuButtonClick}
                        onLinkClick={this.handleSideMenuLinkClick}
                        onHitTest={this.handleSideMenuHitTest}
                        headerBody={() => 
                            <UserCard 
                                collapsed={this.state.sideMenuCollapsed} 
                                name={this.props.user.name} 
                                loginId={this.props.user.loginId} 
                                avatarSize='3em' />
                        }
                    />
                </Responsive>
                    */}        

                <SideMenu routeBased hideOnCollapsed
                        headerText={this.props.app.name}
                        menuItems={this.props.sideMenu.menuItems}
                        collapsed={this.state.sideMenuCollapsed}
                        onMenuButtonClick={this.handleSideMenuMenuButtonClick}
                        onLinkClick={this.handleSideMenuLinkClick}
                    onHitTest={this.handleSideMenuHitTest}
                    /*headerBody={() => 
                        <UserCard 
                            collapsed={this.state.sideMenuCollapsed} 
                            name={this.props.user.name} 
                            loginId={this.props.user.loginId} 
                            avatarSize='3em' />
                    }*/
                />
            </React.Fragment>
        );

        var appsPanel = (
            <SlidingPanel dockAt='right' width='22em'
                title='Apps Drawer'
                className={this.props.appsPanel.className}
                collapsed={this.state.appsPanelCollapsed}
                onHitTest={this.handleAppsPanelHitTest}
                onPanelCollapseButtonClick={this.handleAppsPanelCollapseButtonClick}>
                <AppList appItems={this.props.appsPanel.appItems} onAppItemClick={this.props.onAppItemClick} />
            </SlidingPanel>
        );

        var showAppsPanel = false;
        if ((this.props.appsPanel != null) && 
            (this.props.appsPanel.appItems != null) &&
            (this.props.appsPanel.appItems instanceof Array) &&
            (this.props.appsPanel.appItems.length > 0))
        {
            showAppsPanel = true;    
        }

        return (
            <React.Fragment>
                <div 
                    className={clsx('appkit-skeleton-ui', (this.props.className != null ? this.props.className : ''))}
                    style={this.props.style}
                >
                    {/* Apps drawer */}
                    {(showAppsPanel ? appsPanel : null)}

                    {/* Side menu */}
                    {(showSideMenu ? sideMenu : null)}                 

                    <div className='body'>
                        <Overlay visible={!this.state.sideMenuCollapsed} />
                        <LoadingMask 
                            visible={this.props.loading}
                            text={this.props.loadingText} />
                        <div className='header-row'>
                            {/* Top menu. */}
                            <TopMenu avatar 
                                appsPanel={showAppsPanel}
                                sideMenu={showSideMenu}
                                user={this.props.user}
                                title={this.props.app.name}
                                menuItems={this.props.topMenu.menuItems}
                                menuCollapsed={this.state.sideMenuCollapsed}
                                onSignOutLinkClick={this.handleTopMenuSignOutLinkClick}
                                onAppsPanelLinkClick={this.handleAppsPanelLinkClick}
                                onSideMenuLinkClick={this.handleTopMenuSideMenuLinkClick} 
                                onLinkClick={this.handleTopMenuLinkClick}/>
                        </div>

                        <Scrollbars 
                            className='content'
                            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                        >
                            {/* Body. */}
                            <div>
                                {this.props.children}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    handleSideMenuMenuButtonClick()
    {
        this.toggleSideMenu();
        return;
    }

    handleSideMenuLinkClick(e, args)
    {
        if (this.props.onSideMenuLinkClick != null)
        {
            this.props.onSideMenuLinkClick(args);
        }

        if ((args.value != null) && 
            (typeof (args.value) === "string"))
        {
            if (Url(args.value).isAbsoluteHttpUrl() === true)
            {
                window.open(args.value, '_self');
            }
            else
            {
                this.props.history.push(args.value, args);
            }
        }

        var collapseSideMenu = true;
        if ((args.autoCollapseOnClick != null) &&
            (typeof (args.autoCollapseOnClick) === "boolean"))
        {
            collapseSideMenu = args.autoCollapseOnClick;
        }

        if ((collapseSideMenu === true) &&
            (this.state.sideMenuCollapsed === false))
        {
            this.collapseSideMenu();
        }

        return;
    }

    handleSideMenuHitTest(args)
    {
        if ((args.result === 'outside') &&
            (this.state.sideMenuCollapsed === false))
        {
            this.setState({
                sideMenuCollapsed: true
            });
        }
        return;
    }

    handleTopMenuSideMenuLinkClick()
    {
        this.toggleSideMenu();
        return;
    }

    handleTopMenuLinkClick(e)
    {
        var args = Object.assign({}, e, {
           handled: false 
        });

        if (this.props.onTopMenuLinkClick != null)
        {
            this.props.onTopMenuLinkClick(args);
        }

        if (args.handled === false)
        {
            if ((args.value != null) && 
                (typeof (args.value) === "string"))
            {
                if (Url(args.value).isAbsoluteHttpUrl() === true)
                {
                    window.open(args.value, '_self');
                }
                else
                {
                    this.props.history.push(args.value, args);
                }
            }
        }
        
        return;
    }

    handleTopMenuSignOutLinkClick()
    {
        if (this.props.onSignOut != null)
        {
            this.props.onSignOut();
        }
        return;
    }

    handleAppsPanelLinkClick()
    {
        if (this.props.onAppsPanelOpening != null)
        {
            this.props.onAppsPanelOpening();
        }

        this.setState(function (state) {
            return { appsPanelCollapsed: false };
        });

        return;
    }

    handleAppsPanelCollapseButtonClick()
    {
        if (this.props.onAppsPanelClosing != null)
        {
            this.props.onAppsPannelClosing();
        }

        this.setState(function (state) {
            return { appsPanelCollapsed: true };
        });

        return;
    }

    handleAppsPanelHitTest(args)
    {
        if ((args.result === 'outside') &&
            (this.state.appsPanelCollapsed === false))
        {
            this.setState({
                appsPanelCollapsed: true
            });
        }
        return;
    }

    toggleSideMenu()
    {
        this.setState(function (state)
        {
            return {
                sideMenuCollapsed: !state.sideMenuCollapsed
            };
        }, function () {
            if (this.props.onSideMenuToggle != null)
            {
                this.props.onSideMenuToggle(this.state.sideMenuCollapsed);
            }
        });
        return;
    }

    collapseSideMenu()
    {
        var stateBeforeCollapsed = this.state.sideMenuCollapsed;

        this.setState(function (state)
        {
            return {
                sideMenuCollapsed: true
            };
        }, function () {
            if (stateBeforeCollapsed === false)
            {
                if (this.props.onSideMenuToggle != null)
            {
                this.props.onSideMenuToggle(this.state.sideMenuCollapsed);
            }
            }
        });

        return;
    }
}
SkeletonUi.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    app: PropTypes.shape({
        name: PropTypes.string
    }),
    user: PropTypes.shape({
        name: PropTypes.string,
        loginId: PropTypes.string
    }),
    sideMenu: PropTypes.shape({
        menuItems: PropTypes.array
    }),
    topMenu: PropTypes.shape({
        menuItems: PropTypes.array
    }),
    onSideMenuToggle: PropTypes.func,
    onSideMenuLinkClick: PropTypes.func,
    onTopMenuLinkClick: PropTypes.func,
    onSignOut: PropTypes.func,
    appsPanel: PropTypes.shape({
        className: PropTypes.string,
        appItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            icon: PropTypes.string,
            uri: PropTypes.string.isRequired,
            enabled: PropTypes.bool,
            visible: PropTypes.bool,
            ordinal: PropTypes.number.isRequired
        }))
    }),
    onAppsPanelOpening: PropTypes.func,
    onAppsPanelCollapsing: PropTypes.func,
    onAppItemClick: PropTypes.func
};
SkeletonUi.defaultProps = {
    app: { name: "" },
    user: { 
        name: "",
        loginId: ""
    },
    sideMenu: { menuItems: [] },
    topMenu: { menuItems: [] },
    appsPanel: { className: '' }
};

export default withRouter(SkeletonUi);



