import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Route } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import { Header, Icon, Accordion, List } from 'semantic-ui-react';
import './SideMenu.css';

const SideMenuContext = React.createContext({});
const collapsedIconSize = 'large';
const iconSize = null;


// SideMenu.
class SideMenu extends React.Component 
{
    constructor(props)
    {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleAccordionClick = this.handleAccordionClick.bind(this);
        this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
        this.renderMenuItems = this.renderMenuItems.bind(this);
        this.getHeaderText = this.getHeaderText.bind(this);

        this.node = React.createRef();

        this.state = {
            activeAccordionId: null
        };
    }

    render()
    {
        var collapsedStyle = 'collapsed';
        if (this.props.collapsed === true)
        {
            if (this.props.hideOnCollapsed === true)
            {
                collapsedStyle = 'hide';
            }
        }

        var element = (
            <div className={clsx('appkit-side-menu',
                (this.props.className != null ? this.props.className : ""),
                {'float' : this.props.hideOnCollapsed},
                (this.props.collapsed === true ? collapsedStyle : ''))}
                style={this.props.style}
                ref={this.node}
            >
                <div className='header-area'>
                    <div className='header-row'>
                        {/* Header */}
                        <div className='text-item'>{this.getHeaderText()}</div>
                        {/* Menu button */}
                        <div className='menu-button-item'>
                            <Icon 
                                onClick={this.handleMenuButtonClick}
                                name={(!this.props.hideOnCollapsed ? this.props.mainMenuButtonIcon : this.props.closeButtonIcon)} 
                                size={(!this.props.hideOnCollapsed && this.props.collapsed === true ? collapsedIconSize : iconSize)}/>
                        </div>
                    </div>
                    {this.renderHeaderBody()}
                </div>
                
                <Scrollbars 
                    className='list-area' 
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                >
                    <SideMenuContext.Provider value={{
                        routeBased: this.props.routeBased,
                        selectedLinkId: this.props.selectedLinkId,
                        handleAccordionClick: this.handleAccordionClick,
                        activeAccordionId: this.state.activeAccordionId,
                        collapsed: this.props.collapsed,
                        hideOnCollapsed: this.props.hideOnCollapsed,
                        onLinkClick: this.props.onLinkClick
                    }}>
                        {/*  List */}
                        <List className='list depth-one'>
                            {this.renderChildren()}
                        </List>
                    </SideMenuContext.Provider>
                </Scrollbars>
            </div>
        );

        return element;
    }

    componentDidMount()
    {
        document.addEventListener('mousedown', this.handleDocumentClick);
        return;
    }

    componentWillUnmount()
    {
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }

    handleDocumentClick(e)
    {
        if (this.props.onHitTest != null)
        {
            if (this.node.current.contains(e.target) === false)
            {
                // The element that triggers the event is not part of this side menu.
                this.props.onHitTest({
                    target: e.target,
                    result: 'outside'
                });
            }
            else
            {
                // The element that triggers the event is part of this side menu.
                this.props.onHitTest({
                    target: e.target,
                    result: 'inside'
                });
            }
        }

        return;
    }

    handleMenuButtonClick(e)
    {
        if (this.props.onMenuButtonClick != null)
        {
            this.props.onMenuButtonClick(e);
        }
        return;
    }

    handleAccordionClick(e, accordionId)
    {
        this.setState(function (state, props) { 
            var newState = {
                activeAccordionId: accordionId
            };

            if (accordionId === state.activeAccordionId)
            {
                newState.activeAccordionId = null;
            }

            return newState;
        });
    }

    renderHeader()
    {
        var headerElement = null;
        if (this.props.header != null)
        {
            headerElement = this.props.header();
        }

        return headerElement;
    }

    renderChildren()
    {
        var children = this.props.children;

        if (this.props.menuItems != null)
        {
            children = this.renderMenuItems(this.props.menuItems);
        }
        
        children = React.Children.map(children, (child) => {
            return React.cloneElement(child, { depth: 1 });
        });

        return children;
    }

    renderMenuItems(menuItems)
    {
        var self = this;
        if ((menuItems != null) &&
            (menuItems.length > 0))
        {
            return menuItems.map(function (menuItem) {
                if (menuItem.type === 'header')
                {
                    // Header.
                    return <SideMenu.SectionHeader key={menuItem.id} text={menuItem.text} visible={menuItem.visible} />;
                }
                else
                {
                    if ((menuItem.menuItems != null) &&
                        (menuItem.menuItems.length > 0))
                    {
                        // List.
                        return (
                            <SideMenu.List 
                                key={menuItem.id} 
                                id={menuItem.id} 
                                text={menuItem.text} 
                                icon={menuItem.icon}
                                value={menuItem.value}
                                visible={menuItem.visible}
                            >
                                {self.renderMenuItems(menuItem.menuItems)}
                            </SideMenu.List>
                        );
                    }
                    else
                    {
                        // Link.
                        return (
                            <SideMenu.Link 
                                key={menuItem.id} 
                                id={menuItem.id} 
                                text={menuItem.text} 
                                icon={menuItem.icon} 
                                onClick={menuItem.onClick} 
                                render={menuItem.render}
                                value={menuItem.value}
                                visible={menuItem.visible}
                                autoCollapseOnClick={menuItem.autoCollapseOnClick}/>
                        );
                    }
                }
            });
        }
        return;
    }

    renderHeaderBody()
    {
        var headerBody = null;
        if (this.props.headerBody != null)
        {
            headerBody = this.props.headerBody({
                hideOnCollapsed: this.props.hideOnCollapsed,
                collapsed: this.props.collapsed
            });
        }

        return headerBody;
    }

    getHeaderText()
    {
        if (typeof (this.props.headerText) === "function")
        {
            return this.props.headerText();
        }
        else
        {
            return this.props.headerText;
        }
    }
}
SideMenu.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    headerText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func]),
    headerBody: PropTypes.func,
    mainMenuButtonIcon: PropTypes.string,
    closeButtonIcon: PropTypes.string,
    hideOnCollapsed: PropTypes.bool,
    routeBased: PropTypes.bool,
    selectedLinkId: PropTypes.string,
    menuItems: PropTypes.array,
    collapsed: PropTypes.bool,
    onMenuButtonClick: PropTypes.func,
    onLinkClick: PropTypes.func,
    onHitTest: PropTypes.func
};
SideMenu.defaultProps = {
    mainMenuButtonIcon: 'bars',
    closeButtonIcon: 'arrow left',
    hideOnCollapsed: false,
    routeBased: false
};

// SectionHeader.
SideMenu.SectionHeader = class extends React.Component
{
    render()
    {
        if (this.props.visible === false)
        {
            return null;
        }
        
        return (
            <div className='section'>
                <Header>{this.props.text}</Header>
            </div>
        );
    }
}
SideMenu.SectionHeader.propTypes = {
    text: PropTypes.string.isRequired,
    depth: PropTypes.number,
    visible: PropTypes.bool,
    id: PropTypes.string
};
SideMenu.SectionHeader.contextType = SideMenuContext;

// Link.
SideMenu.Link = class extends React.Component
{
    constructor(props)
    {
        super(props);

        this.isSelected = this.isSelected.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
    }

    render()
    {
        if (this.props.visible === false)
        {
            return null;
        }

        var bodyElement = 
            <div className='item-row'>
                <div className='icon-item'><Icon name={this.props.icon} size={(!this.context.hideOnCollapsed && this.context.collapsed === true ? collapsedIconSize : iconSize)}/></div>
                <div className='text-item'>{this.props.text}</div>
            </div>;
                
        return (
            <Route
                path={this.props.value}
                children={({ match }) => {
                    return (
                        <List.Item className={clsx('list-item', 
                            (this.context.routeBased ? (match ? 'selected' : '') : (this.isSelected(this.props.id) ? 'selected': '')),
                            (this.props.depth > 1 ? 'li-two': 'li-one')) }
                            onClick={this.handleListItemClick}
                        >
                            {(this.props.render != null ? this.props.render(bodyElement, {
                                id: this.props.id,
                                text: this.props.text,
                                depth: this.props.depth,
                                value: this.props.value
                            }) : bodyElement)}
                        </List.Item>
                    );
                }}
            />
        );
    }

    handleListItemClick(e)
    {
        if (this.props.onClick != null)
        {
            this.props.onClick(e, {
                id: this.props.id,
                text: this.props.text,
                depth: this.props.depth,
                value: this.props.value,
                autoCollapseOnClick: this.props.autoCollapseOnClick
            });
        }

        if (this.context.onLinkClick != null)
        {
            this.context.onLinkClick(e, {
                id: this.props.id,
                text: this.props.text,
                depth: this.props.depth,
                value: this.props.value,
                autoCollapseOnClick: this.props.autoCollapseOnClick
            });
        }
        return;
    }

    isSelected(id)
    {
        var selected = false;

        if (this.context.routeBased === false)
        {
            // Read selectedLinkId from SideMenu context.
            if ((this.context.selectedLinkId != null) &&
                (id != null) &&
                (id === this.context.selectedLinkId))
            {
                selected = true;
            }
        }

        return selected;
    }
};
SideMenu.Link.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    value: PropTypes.any,
    onClick: PropTypes.func,
    depth: PropTypes.number,
    visible: PropTypes.bool,
    render: PropTypes.func
};
SideMenu.Link.contextType = SideMenuContext;

// List.
SideMenu.List = class extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleAccordionTitleClick = this.handleAccordionTitleClick.bind(this);
        this.isActive = this.isActive.bind(this);
        this.isChildSelected = this.isChildSelected.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
        this.getChild = this.getChild.bind(this);
    }

    render()
    {
        if (this.props.visible === false)
        {
            return null;
        }

        return (
            <Route
                path={this.props.value}
                children={({ match }) => (
                    <List.Item className='list-item li-lst'>
                        <Accordion fluid styled>
                            <Accordion.Title 
                                className={clsx(
                                    (this.context.routeBased ? ((this.isActive(this.props.id) === false) && match ? 'selected' : '') : ((this.isActive(this.props.id) === false) && this.isChildSelected() ? 'selected': '')))}
                                active={this.isActive(this.props.id)}
                                index={this.props.id} 
                                onClick={this.handleAccordionTitleClick
                            }>
                                <div className='item-row'>
                                    <div className='icon-item'><Icon name={this.props.icon} size={(!this.context.hideOnCollapsed && this.context.collapsed === true ? collapsedIconSize : iconSize)}/></div>
                                    <div className='text-item'>{this.props.text}</div>
                                    <div className='dropdown-icon-item'><Icon name={(this.isActive(this.props.id) === false ? 'chevron right' : 'chevron down')} /></div>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content active={this.isActive(this.props.id)} style={{borderTop: 0}}>
                                <List className='list depth-two'>
                                    {this.renderChildren()}
                                </List>
                            </Accordion.Content>
                        </Accordion>
                    </List.Item>
                )}
            />
        );
    }

    handleAccordionTitleClick(e, titleProps)
    {
        var id = titleProps.index;

        if (this.context.handleAccordionClick != null)
        {
            this.context.handleAccordionClick(e, id);
        }

        return;
    }

    isActive(id)
    {
        var active = false;

        if ((this.context.activeAccordionId != null) &&
            (this.context.activeAccordionId === id))
        {
            active = true;
        }

        return active;
    }

    isChildSelected()
    {
        var isChildSelected = false;

        if (this.context.routeBased === false)
        {
            // Read selectedLinkId from SideMenu context.
            if ((this.context != null) && 
                (this.context.selectedLinkId != null))
            {
                var foundChildren = this.getChild(this.props.children, 
                    (child) => 
                        (child.props.id === this.context.selectedLinkId) &&
                        (child.props.visible === true));
                if (foundChildren.length > 0)
                {
                    isChildSelected = true;
                }
            }
        }

        return isChildSelected;
    }

    getChild(children, compare)
    {
        if (React.Children.count(children) === 0)
        {
            return null;
        }
        else
        {
            var foundChildren = React.Children.map(children, (child) => {
                if ((compare == null) || 
                    (typeof (compare) !== "function"))
                {
                    throw new Error("getChild function expects comparer argument of function type");
                }

                if (compare(child) === true)
                {
                    return child;
                }
                else
                {
                    return this.getChild(child.props.children, compare);
                }
            });

            return [].concat(...foundChildren);
        }
    }

    renderChildren()
    {
        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { depth: this.props.depth + 1 });
        });
    }
};
SideMenu.List.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    value: PropTypes.any,
    depth: PropTypes.number,
    visible: PropTypes.bool
};
SideMenu.List.contextType = SideMenuContext;

export { SideMenu };