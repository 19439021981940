import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from 'semantic-ui-react';
import { Overlay } from './Overlay.js';
import './SlidingPanel.css';

class SlidingPanel extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);

        this.node = React.createRef();
    }

    render()
    {
        var { props } = this;

        var style = {};
        if (("width" in props) &&
            (typeof (props.width) === "string"))
        {
            style.width = props.width;
        }

        var dockAt = 'right';
        if (typeof (props.dockAt) === 'string')
        {
            if ((props.dockAt.toLowerCase() === 'left') ||
                (props.dockAt.toLowerCase() === 'right'))
            {
                dockAt = props.dockAt.toLowerCase();
            }
        }

        return (
            <div className={clsx(
                'appkit-sliding-panel', 
                (props.className != null ? props.className : ''))}
            >
                <Overlay visible={!props.collapsed} />
                <div className={clsx('panel', dockAt,
                    { 'hide': props.collapsed })}
                    style={style}
                    ref={this.node}
                >
                    <div className='header-area'>
                        <div className='header-row'>
                            {(
                                dockAt === 'left' ? 
                                <React.Fragment>
                                    {/* Title */}
                                    <div className='title-item'>{props.title}</div>
                                    {/* Collapse button */}
                                    <div className='button-item'>
                                        <Icon name='left arrow' onClick={props.onPanelCollapseButtonClick}/>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {/* Title */}
                                    <div className='title-item'>{props.title}</div>
                                    {/* Collapse button */}
                                    <div className='button-item'>
                                        <Icon name='right arrow' onClick={props.onPanelCollapseButtonClick}/>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <Scrollbars 
                        className='body-area' 
                        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    >
                        {props.children}
                    </Scrollbars>
                </div>
            </div>
        );
    }

    componentDidMount()
    {
        document.addEventListener('mousedown', this.handleDocumentClick);
        return;
    }

    componentWillUnmount()
    {
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }

    handleDocumentClick(e)
    {
        if (this.props.onHitTest != null)
        {
            if (this.node.current.contains(e.target) === false)
            {
                // The element that triggers the event is not part of this sliding panel.
                this.props.onHitTest({
                    target: e.target,
                    result: 'outside'
                });
            }
            else
            {
                // The element that triggers the event is part of this sliding panel.
                this.props.onHitTest({
                    target: e.target,
                    result: 'inside'
                });
            }
        }

        return;
    }
}

SlidingPanel.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    title: PropTypes.string,
    dockAt: PropTypes.string,
    collapsed: PropTypes.bool,
    onPanelCollapseButtonClick: PropTypes.func,
    onHitTest: PropTypes.func
};

SlidingPanel.defaultProps = {
    dockAt: 'left'
};

export { SlidingPanel };