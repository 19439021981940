import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { LoadingMask } from './LoadingMask.js';
import './Card.css';

function Card(props)
{
    var { children } = props;

    var loading = false;
    if (('loading' in props) &&
        (props.loading === true))
    {
        loading = true;
    }

    var loadingText = '';
    if ((typeof (props.loadingText) === 'string') &&
        (props.loadingText.length > 0))
    {
        loadingText = props.loadingText;
    }

    return (
        <div className='appkit-card'>
            <LoadingMask className='card-loading-mask' visible={loading} text={loadingText} inverted/>
            {children}
        </div>
    );
}
Card.propTypes = {
    loading: PropTypes.bool,
    loadingText: PropTypes.string
};

Card.Header = function (props)
{
    var { left, right } = props;

    return (
        <div className='card-header'>
            <div className='card-left'>{left}</div>
            <div className='card-right'>{right}</div>
        </div>
    );
};
Card.Header.propTypes = {
    left: PropTypes.node,
    right: PropTypes.node
};

Card.Body = function (props)
{
    var { children, padding } = props;

    var withPadding = true;
    if ((typeof (padding) === 'boolean') &&
        (padding === false))
    {
        withPadding = false;
    }

    return (
        <div className={clsx('card-body', (withPadding ? 'padding' : ''))}>
            {children}
        </div>
    );
}
Card.Body.propTypes = {
    padding: PropTypes.bool
};


export { Card };
