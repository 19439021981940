import { AppError } from './AppError.js';

// Set header value using deep merge.
function setHeader (request, name, values, delimiter)
{
    var newHeaderValue = null;

    if (request.headers == null)
    {
        request.headers = {};
    }

    var currentValues = [];
    if (typeof (request.headers[name]) === 'string')
    {
        currentValues = request.headers[name].split(delimiter);
    }

    if ((currentValues instanceof Array) &&
        (values instanceof Array))
    {
        var combinedValues = [...currentValues, ...values];
        newHeaderValue = combinedValues.join(delimiter);
        request.headers[name] = newHeaderValue;
    }

    return newHeaderValue;
}

// Gracefully handle HTTP 204 no content and return http response in JSON format.
async function getJson(response)
{
    var json = null;
    if (response != null)
    {
        var content = await response.text();
        json = (content.length > 0 ? JSON.parse(content) : null);
    }

    return json;
}

class RequestService
{
    constructor(acquireToken)
    {
        this.acquireToken = acquireToken;
    }

    async get(url, request, outputAsJson)
    {
        // Informs server to expect JSON response (Accept).
        setHeader(request, 'Accept', ['application/json'], ',');

        // Ensures method is HTTP get.
        var response = await this.request(url, Object.assign({}, request, {
            method: 'GET'
        }));

        if ((typeof (outputAsJson) === "boolean") &&
            (outputAsJson === false))
        {
            // If 'outputAsJson' is set to false, return as raw response for
            // the caller to further process the raw response.
            return response;
        }
        else
        {
            return await getJson(response);
        }
    }

    // Sends a HTTP post request with JSON 'Content-Type' header and JSON body data.
    async post(url, request, outputAsJson)
    {
        // Ensures method is HTTP post.
        var req = Object.assign({}, request, {
            method: 'POST'
        });

        // Informs server request content is JSON type (Content-Type) and expect
        // response of JSON type (Accept).
        setHeader(req, 'Content-Type', ['application/json'], ';');
        setHeader(req, 'Accept', ['application/json'], ',');

        // Ensures body data is converted to JSON.
        req.body = JSON.stringify(req.body);

        var response = await this.request(url, req);

        if ((typeof (outputAsJson) === "boolean") &&
            (outputAsJson === false))
        {
            // If 'outputAsJson' is set to false, return as raw response for
            // the caller to further process the raw response.
            return response;
        }
        else
        {
            return await getJson(response);
        }
    }

    // Sends a HTTP put request with JSON 'Content-Type' header and JSON body data.
    async put(url, request, outputAsJson)
    {
        // Ensures method is HTTP put.
        var req = Object.assign({}, request, {
            method: 'PUT'
        });

        // Informs server request content is JSON type (Content-Type) and expect
        // response of JSON type (Accept).
        setHeader(req, 'Content-Type', ['application/json'], ';');
        setHeader(req, 'Accept', ['application/json'], ',');

        // Ensures body data is converted to JSON.
        req.body = JSON.stringify(req.body);

        var response = await this.request(url, req);

        if ((typeof (outputAsJson) === "boolean") &&
            (outputAsJson === false))
        {
            // If 'outputAsJson' is set to false, return as raw response for
            // the caller to further process the raw response.
            return response;
        }
        else
        {
            return await getJson(response);
        }
    }

    async request(url, request)
    {
        var response = null;

        // Destructures request into 3 variables: 'scopes', 'headers' 
        // and 'requestProperties'. Variable 'originalRequest' 
        // contains all original request properties except 'scopes' 
        // and 'headers' properties.
        var { scopes, headers, ...originalRequest } = request;

        // Ensures url and scopes are present with value.
        if ((typeof (url) !== "string") || 
            (url === ""))
        {
            throw new Error('Request url is invalid');
        }
        if ((scopes instanceof Array) === false)
        {
            throw new Error('Request scopes is invalid');
        }

        // Gets access token from security token service. The
        // access token is used later to make authenticated 
        // request to server.
        var tokenResponse = await this.acquireToken({
            scopes: scopes
        });

        if (tokenResponse == null)
        {
            throw new Error("Unable to get a token response");
        }

        var requestHeaders = { 
            // Sets request to use bearer authentication. The bearer value is the access token.
            Authorization: `Bearer ${tokenResponse.accessToken}`,
            // Sets request's cache control as 'no-store' by default.
            'Cache-Control': 'no-store',
            // Sets request's pragma as 'no-cache' by default.
            Pragma: 'no-cache'
        };

        // Merges with original request headers.
        if (headers != null)
        {
            requestHeaders = Object.assign({}, requestHeaders, headers);
        }

        // Constructs request parameters from default values and original request properties.
        var requestParameters = Object.assign({
            mode: 'cors',
            headers: requestHeaders
        }, originalRequest);

        try
        {
            response = await fetch(url, requestParameters);
        }
        catch (error)
        {
            throw AppError.create('Application encountered network error when performing a HTTP request', 500);
        }

        if (response == null)
        {
            throw AppError.create('HTTP request received unexpected empty response', 500);
        }
        else if (response.ok === false)
        {
            let e = await response.text();
            try
            {
                let jsonError = JSON.parse(e);
                if (('status' in jsonError) &&
                    ('detail' in jsonError) &&
                    ('title' in jsonError))
                {
                    throw AppError.create(jsonError.title, jsonError.status, jsonError.detail);
                }
                else
                {
                    throw new Error(e);
                }
            }
            catch
            {
                throw new Error(e);
            }
        }
        
        return response;
    }
}

export { RequestService };