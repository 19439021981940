import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './SplashScreen.css';
import { Container, Icon, Header } from 'semantic-ui-react';

function SplashScreen(props)
{
    var { text } = props;
    var title = '{Title}';
    var subtitle = '{Subtitle}'
    if  (text != null)
    {
        if ('title' in text)
        {
            title = text.title;
        }

        if ('subtitle' in text)
        {
            subtitle = text.subtitle;
        }
    }

    var textComponent = (
        <Header as='h2'>
            {title}
            <Header.Subheader>{subtitle}</Header.Subheader>
        </Header>
    );

    var defaultIconComponent = (
        <Icon.Group size='massive'>
            <Icon loading size='big' name='circle notch' color='blue' />
            <Icon size='small' name='window maximize outline' color='black' />
        </Icon.Group>
    );

    return (
        <div className={clsx('appkit-splash-screen', (props.className != null ? props.className : ""))}>
            <div className='content centered'>
                <Container textAlign='center'>
                    <div>
                        {(props.icon != null ? props.icon() : defaultIconComponent)}
                    </div>
                    {textComponent}
                </Container>
            </div>
        </div>
    );
}
SplashScreen.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.func,
    text: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
};

export { SplashScreen };