import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon, Popup } from 'semantic-ui-react';
import './ContextMenu.css';

class ContextMenu extends React.Component
{
    render()
    {
        var { className, ...popupProperties } = this.props;

        var contextMenu = (
            <div className="context-menu">
                {this.props.children}
            </div>
        );

        var popup = (
            <Popup flowing pinned
                className={clsx('appkit-context-menu', (className != null ? className : ''))}
                on='click'
                {...popupProperties}      
            >
                {contextMenu}
            </Popup>
        );

        return popup;
    }
}
ContextMenu.propTypes = {
    className: PropTypes.string
};

ContextMenu.Item = class extends React.Component
{
    render()
    {
        var content = (
            <React.Fragment>
                <Icon name={this.props.icon} />
                <div className='text'>{this.props.text}</div>
            </React.Fragment>
        );

        if (this.props.children != null)
        {
            content = this.props.children;
        }

        var item = (
            <div className={clsx('item', 
                (this.props.className != null ? this.props.className : ''),
                {'actionable': (this.props.onClick != null ? true : false)})}
                onClick={this.props.onClick}
            >
                {content}
            </div>
        );

        return item;
    }
}
ContextMenu.Item.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    text: PropTypes.string
};

ContextMenu.Divider = class extends React.Component
{
    render()
    {
        return (
            <div className={clsx('item', 'divider', (this.props.className != null ? this.props.className : ''))}></div>
        );
    }
}
ContextMenu.Divider.propTypes = {
    className: PropTypes.string
};

export { ContextMenu };