import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Avatar from 'react-avatar';
import './UserCard.css';

class UserCard extends React.Component
{
    render()
    {
        return (
            <div className={clsx('appkit-user-card ', 
                (this.props.className !== null ? this.props.className : ''),
                (this.props.collapsed ? 'collapsed': ''))}>
                <div className='avatar-item'>
                    <Avatar 
                        name={this.props.name} 
                        size={this.props.avatarSize} round />
                </div>
                <div className='profile-item'>
                    <div className='name'>{this.props.name}</div>
                    <div className='login-id'>{this.props.loginId}</div>
                </div>
            </div>
        );
    }
}
UserCard.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    loginId: PropTypes.string,
    avatarSize: PropTypes.string,
    collapsed: PropTypes.bool
};
UserCard.defaultProps = {
    name: '',
    loginId: '',
    avatarSize: '3em'
};

export { UserCard };