import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './AppList.css';

function AppList(props)
{
    var appItems = null;
    
    if (props.appItems != null)
    {
        appItems = props.appItems.map(appItem => {
            if (appItem.visible === true)
            {
                return (
                    <AppList.Item
                        key={appItem.id} id={appItem.id} displayName={appItem.displayName} icon={appItem.icon}
                        uri={appItem.uri} description={appItem.description} enabled={appItem.enabled}
                        onClick={props.onAppItemClick}/>
                );
            }
            else
            {
                return null;
            }
        });
    }

    return (
        <React.Fragment>
            <div className={clsx(
                'appkit-app-list', 
                (props.className != null ? props.className : ""))}
            >
                <div className='list-area'>
                    {appItems}
                </div>
            </div>
        </React.Fragment>
    );
}
AppList.propTypes = {
    className: PropTypes.string,
    appItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string,
        uri: PropTypes.string.isRequired,
        enabled: PropTypes.bool,
        visible: PropTypes.bool,
        ordinal: PropTypes.number.isRequired
    })),
    onAppItemClick: PropTypes.func
};

AppList.Item = class extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleAppItemClick = this.handleAppItemClick.bind(this);
    }

    render()
    {
        var icon = <i aria-hidden="true" className='blue large icon cube'/>;

        if ((this.props.icon != null) &&
            (typeof (this.props.icon) === 'string'))
        {
            var svgString = encodeURIComponent(this.props.icon);
            var dataUri = `url("data:image/svg+xml,${svgString}")`;
            icon = 
                <i aria-hidden="true" className='icon' style={{
                    background: dataUri
                }}/>
        }

        return (
            <div className={clsx('app-item', (this.props.enabled === false ? 'disabled' : ''))} onClick={this.handleAppItemClick}>
                <div className='app-icon'>
                    <div className='icon-item'>
                        {icon}
                    </div>
                </div>
                <div className='app-info'>
                    <div className='text'>{this.props.displayName}</div>
                    <div className='desc'>
                        {this.props.description}
                    </div>
                    {(this.props.enabled === false ? <div className='subtitle'>Coming soon</div> : null)}
                </div>
            </div>
        );
    }

    handleAppItemClick()
    {
        if ((this.props.enabled !== false) &&
            (this.props.onClick != null))
        {
            this.props.onClick({
                id: this.props.id,
                displayName: this.props.displayName,
                description: this.props.description,
                uri: this.props.uri
            });
        }

        return;
    }
}
AppList.Item.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    uri: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    visible: PropTypes.bool,
    onClick: PropTypes.func
};

export { AppList };