import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

class TelemetryServiceInternal
{
    initialize(name, config) 
    {
        const defaultAppInsightsConfig = {
            distributedTracingMode: DistributedTracingModes.W3C,
            maxBatchInterval: 5000,
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            autoTrackPageVisitTime: true
        };

        const appInsightsConfig = Object.assign({ }, defaultAppInsightsConfig, config);

        const appInsights = new ApplicationInsights({ config: appInsightsConfig });
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((telemetryItem) => {
            telemetryItem.tags["ai.cloud.role"] = name;
        });

        console.log("Telemetry name: ", name);

        this.appInsights = appInsights;
    }
}

const TelemetryService = new TelemetryServiceInternal();
export { TelemetryService };