import React from 'react';
import PropTypes from 'prop-types';
//import { AppError } from '../services';
import { SplashScreen } from './SplashScreen.js';
import { LinkButton } from './LinkButton.js';
import { Icon } from 'semantic-ui-react';
import './ErrorSplashScreen.css';

function ErrorSplashScreen(props)
{
    var message = "Application encountered unrecoverable unhandled error";
    var additionalMessage = "Sorry, we're unable to recover from the problem unfortunately.";

    if (props.error != null)
    {
        message = props.error.message;

        if (('detail' in props.error) &&
            (props.error.detail != null))
        {
            additionalMessage = props.error.detail;
        }

        // Can be further enhanced to include friendly message based on http status code.
        /*
        if (e instanceof AppError)
            {
                switch (e.status)
                {
                    // Note:
                    // Can be further extended to include more messages in the future.
                    
                    // Unauthorized
                    case 401: 
                    {
                        error = { message:`(HTTP ${e.status}) You are unauthorized to access the resource` }
                        break;
                    }
                    default: 
                    {
                        error = { message: `(HTTP ${e.status}) ${e.message}` };
                        break;
                    }
                }
            }
        */
    }

    return (
        <SplashScreen className='appkit-error-splash-screen' text={{
            title: 'Opps! Something went wrong..',
            subtitle: 
                <div>
                    <p className='error'>{message}</p>
                    <p className='subtitle'>{additionalMessage}<br />
                    Click <LinkButton onClick={() => window.location.reload() }>here</LinkButton> to retry.&nbsp;
                    Alternatively, you can try <LinkButton onClick={props.onLogout}>logout</LinkButton> and re-login if the problem persists.</p>
                </div>}}
            icon={() => {
                return (
                    <Icon.Group size='massive'>
                        <Icon size='large' name='frown outline' color='black' />
                    </Icon.Group>
                );
            }}
        />
    );
}
ErrorSplashScreen.propTypes = {
    error: PropTypes.object.isRequired
};

export { ErrorSplashScreen };