class UrlUtils
{
    constructor(url)
    {
        this.url = url;
    }

    isAbsoluteHttpUrl()
    {
        var regex = /^https?:\/\//i;
        return regex.test(this.url);
    }

    components()
    {
        var components = {};

        if ((typeof(this.url) !== "string") ||
            (this.url.length === 0))
        {
            return components;
        }

        // Example url: http://abc.com:3000?prop=value#somevalue
        var questionMarkPosition = this.url.indexOf("?");

        // Example: http://abc.com:3000
        var s = this.url;
        if (questionMarkPosition !== -1)
        {
            s = this.url.slice(0, questionMarkPosition);
        }

        // Example:
        // Url: http://abc.com:3000
        // parts[0] = http:
        // parts[1] = 
        // parts[2] = abc.com:3000
        var parts = s.split('/');
        var protocol = parts[0].slice(0, parts[0].length - 1); // Remove the trailing ':'.
        var host = parts[2];
        var path = "";
        if (parts.length > 3)
        {
            path = parts.slice(3).join("/");
            if (questionMarkPosition !== -1)
            {
                path = path.slice(0, questionMarkPosition);
            }
        }
        
        var hostParts = host.split(':');
        var hostname = "";
        var port = null;
        if ((hostParts != null) && (hostParts.length > 0))
        {
            hostname = hostParts[0];
            port = hostParts[1];
        }

        var queryString = this.queryString();
        var fragment = this.fragment();

        components = {
            protocol: protocol,
            host: host,
            hostname: hostname,
            port: port,
            path: path,
            queryString: queryString,
            fragment: fragment,
            origin: protocol + "://" + host
        };

        return components;
    }

    params(obj)
    {
        if (typeof (obj) === "object")
        {
            for (var key in obj)
            {
                if (obj.hasOwnProperty(key) === true)
                {
                    if ((typeof (obj[key]) === "string") ||
                        (typeof (obj[key]) === "number"))
                    {
                        var regex = new RegExp('{' + key + '}', "ig");
                        this.url = this.url.replace(regex, obj[key].toString());
                    }
                }
            }
        }
        
        return this;
    }

    queryString(obj)
    {
        if (obj == null)
        {
            // Get query string fields.
            let queryStringObj = {};
            if ((typeof (this.url) === "string") &&
                (this.url.length > 0))
            {
                let questionMarkPosition = this.url.indexOf("?");
                if (questionMarkPosition !== -1)
                {
                    let queryString = this.url.slice(questionMarkPosition + 1);
                    let hashPosition = queryString.lastIndexOf("#");
                    if (hashPosition !== -1)
                    {
                        queryString = queryString.slice(0, hashPosition);
                    }

                    if (queryString.length > 0)
                    {
                        let keyValuePairs = queryString.split("&");
                        if ((keyValuePairs != null) && (keyValuePairs.length > 0))
                        {
                            let i = 0;
                            for (i = 0; i <= (keyValuePairs.length - 1); i++)
                            {
                                let keyValuePair = keyValuePairs[i].split("=");
                                if ((keyValuePair != null) && (keyValuePair.length > 0))
                                {
                                    if (keyValuePair.length === 2)
                                    {
                                        queryStringObj[keyValuePair[0]] = decodeURIComponent(keyValuePair[1]);
                                    }
                                    else
                                    {
                                        queryStringObj[keyValuePair[0]] = null;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return queryStringObj;
        }
        else
        {
            if (typeof (obj) === "object")
            {
                // Set query string.
                if (typeof (this.url) === "string")
                {
                    let currentQueryStringObj = this.queryString();
                    let newQueryStringObj = Object.assign({}, currentQueryStringObj, obj);

                    let queryString = "";
                    for (let key in newQueryStringObj)
                    {
                        if (newQueryStringObj.hasOwnProperty(key) === true)
                        {
                            if ((typeof (newQueryStringObj[key]) === "string") ||
                                (typeof (newQueryStringObj[key]) === "number") ||
                                (typeof (newQueryStringObj[key]) === "boolean"))
                            {
                                if (queryString.length > 0)
                                {
                                    queryString += "&";
                                }
                                queryString += encodeURIComponent(key) + "=" + 
                                    encodeURIComponent(newQueryStringObj[key].toString());
                            }
                        }
                    }

                    let questionMarkPosition = this.url.indexOf("?");
                    let hashPosition = this.url.lastIndexOf("#");

                    if (questionMarkPosition === -1)
                    {
                        if (hashPosition === -1)
                        {
                            // No query string and no fragment.
                            // Example: http://abc.com
                            this.url += "?" + queryString;
                        }
                        else
                        {
                            // No query string but with fragment.
                            // Example: http://abc.com#somevalue
                            let path = this.url.slice(0, hashPosition);
                            let fragment = this.url.slice(hashPosition);
                            this.url = `${path}?${queryString}${fragment}`;
                        }
                    }
                    else
                    {
                        if (hashPosition === -1)
                        {
                            // Query string without fragment.
                            // Example: http://abc.com?prop=value
                            let path = this.url.slice(0, questionMarkPosition);
                            this.url = `${path}?${queryString}`;
                        }
                        else
                        {
                            // Query string with fragment.
                            // Example: http://abc.com?prop=value#somevalue
                            let path = this.url.slice(0, questionMarkPosition);
                            let fragment = this.url.slice(hashPosition);
                            this.url = `${path}?${queryString}${fragment}`;
                        }
                    }
                }
                return this;
            }
            else
            {
                throw new Error("queryString function expects argument of object type if provided");
            }
        }
    }

    fragment(obj)
    {
        if (obj == null)
        {
            // Get fragment.
            let fragmentObj = {};
            if ((typeof (this.url) === "string") &&
                (this.url.length > 0))
            {
                let hashPosition = this.url.indexOf("#");
                if (hashPosition !== -1)
                {
                    let fragment = this.url.slice(hashPosition + 1);

                    if (fragment.length > 0)
                    {
                        let keyValuePairs = fragment.split("&");
                        if ((keyValuePairs != null) && (keyValuePairs.length > 0))
                        {
                            let i = 0;
                            for (i = 0; i <= (keyValuePairs.length - 1); i++)
                            {
                                let keyValuePair = keyValuePairs[i].split("=");
                                if ((keyValuePair != null) && (keyValuePair.length > 0))
                                {
                                    if (keyValuePair.length === 2)
                                    {
                                        fragmentObj[keyValuePair[0]] = decodeURIComponent(keyValuePair[1]);
                                    }
                                    else
                                    {
                                        fragmentObj[keyValuePair[0]] = null;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return fragmentObj;
        }
        else
        {
            if (typeof (obj) === "object")
            {
                // Set fragment.
                if (typeof (this.url) === "string")
                {
                    let currentFragmentObj = this.fragment();
                    let newFragmentObj = Object.assign({}, currentFragmentObj, obj);

                    let fragment = "";
                    for (let key in newFragmentObj)
                    {
                        if (newFragmentObj.hasOwnProperty(key) === true)
                        {
                            if ((typeof (newFragmentObj[key]) === "string") ||
                                (typeof (newFragmentObj[key]) === "number") ||
                                (typeof (newFragmentObj[key]) === "boolean"))
                            {
                                if (fragment.length > 0)
                                {
                                    fragment += "&";
                                }
                                fragment += encodeURIComponent(key) + "=" + 
                                    encodeURIComponent(newFragmentObj[key].toString());
                            }
                        }
                    }

                    let hashPosition = this.url.lastIndexOf("#");

                    if (hashPosition === -1)
                    {
                        // No fragment.
                        // Example: http://abc.com
                        this.url += "#" + fragment;
                    }
                    else
                    {
                        // With fragment.
                        // Example: http://abc.com#key=value
                        let path = this.url.slice(0, hashPosition);
                        this.url = `${path}#${fragment}`;
                    }
                }
                return this;
            }
            else
            {
                throw new Error("fragment function expects argument of object type if provided");
            }
        }
    }

    toString()
    {
        return this.url;
    }
}

function Url(url)
{
    return new UrlUtils(url);
}

export { Url };