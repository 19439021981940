import React from 'react';
import clsx from 'clsx';
import { SplashScreen } from './SplashScreen.js';
import './LoadingSplashScreen.css';

class LoadingSplashScreen extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            visible: false
        };
    }

    render()
    {
        return (
            <SplashScreen text={{
                title: 'My Modern App',
                subtitle: 'Application is loading...'}}
                className={clsx('appkit-loading-splash-screen ', { 'visible': this.state.visible })}
            />
        );
    }

    componentDidMount()
    {
        var self = this;
        
        window.setTimeout(() => {
            self.setState({
                visible: true
            });
        }, 1);
    }
}

export { LoadingSplashScreen };