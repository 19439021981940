import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Overlay.css';

function Overlay(props)
{
    return (
        <div className={clsx(
            'appkit-overlay-mask', 
            {'visible': props.visible},
            {'inverted': props.inverted},
            (props.className != null ? props.className : ""))}
        >
            <div className='overlay-content'>{props.children}</div>
        </div>
    );
}
Overlay.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    inverted: PropTypes.bool
};

export { Overlay };