import React from 'react';
import * as Msal from '@azure/msal-browser';
import { Url } from '../utils';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LoadingSplashScreen, ErrorSplashScreen, LogoutSplashScreen, AuthInteractionRequiredSplashScreen } from "../ui";

// 1 second is 1000 milliseconds.
const second = 1000;

const AppServiceContext = React.createContext({
    login: null,
    logout: null,
    acquireToken: null,
    checkIfAuthenticated: null,
    getUserAccount: null,
    getAppContext: null,
    updateAppContext: null,
    setAppError: null,
    clearAppError: null
});

function ProtectedApp(props)
{
    var { onConfigure, ...rest }  = props;

    var config = onConfigure();
    const msal = new Msal.PublicClientApplication(config);

    var silentAuthCallbackPath = Url(config.auth.silentRedirectUri).components().path;
    if (silentAuthCallbackPath[0] !== "/")
    {
        silentAuthCallbackPath = "/" + silentAuthCallbackPath;
    }

    var postLogoutRedirectRoute = null;
    var postLogoutRedirectPath = Url(config.auth.postLogoutRedirectUri).components().path;
    if ((typeof (postLogoutRedirectPath) === 'string') &&
        (postLogoutRedirectPath.length > 0))
    {
        if (postLogoutRedirectPath[0] !== "/")
        {
            postLogoutRedirectPath = "/" + postLogoutRedirectPath;
        }

        var loginUri = config.appUri;
        if ((typeof (config.appBasename) === 'string') &&
            (config.appBasename.length > 0))
        {
            if ((loginUri[loginUri.length - 1] !== '/') &&
                (config.appBasename[0] !== '/'))
            {
                loginUri += '/';
            }
            loginUri += config.appBasename;
        }
        
        var logoutDisplay = <LogoutSplashScreen loginUri={loginUri}/>;

        if (rest.renderLogout != null)
        {
            logoutDisplay = rest.renderLogout({
                loginUri: loginUri,
                appUri: rest.appUri,
                appBasename: rest.appBasename
            });
        }

        postLogoutRedirectRoute = <Route path={postLogoutRedirectPath} render={() => logoutDisplay}/>;
    }

    return (
        <Router basename={config.appBasename}>
            <Switch>
                {postLogoutRedirectRoute}

                <Route path={silentAuthCallbackPath} render={() => null} />

                <Route render={() => {
                    return (<ProtectedAppManager msal={msal} config={config} {...rest}/>);
                }}/>
            </Switch>
        </Router>
    );
}

class ProtectedAppManager extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.state = {
            isAuthenticated: null,
            appContext: { error: null }
        };

        // Required scopes for login feature.
        this.loginScopes = [
            'openid', 'profile'
        ];

        this.msal = this.props.msal;
        
        // For intervally check auth status.
        this.authStatePollingTimerId = null;
        this.enableAuthStatePolling = this.enableAuthStatePolling.bind(this);
        this.disableAuthStatePolling = this.disableAuthStatePolling.bind(this);

        // Handles auth redirection callback.
        this.handleAuthRedirectCallback = this.handleAuthRedirectCallback.bind(this);
        // this.msal.handleRedirectCallback(this.handleAuthRedirectCallback);

        this.checkIfAuthenticated = this.checkIfAuthenticated.bind(this);
        this.getUserAccount = this.getUserAccount.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.acquireToken = this.acquireToken.bind(this);
        this.updateAppContext = this.updateAppContext.bind(this);
        this.setAppError = this.setAppError.bind(this);
        this.clearAppError = this.clearAppError.bind(this);

        this.hasError = this.hasError.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
        this.renderError = this.renderError.bind(this);
    }

    render()
    {
        var appService = {
            login: this.login,
            logout: this.logout,
            acquireToken: this.acquireToken,
            checkIfAuthenticated: this.checkIfAuthenticated,
            getUserAccount: this.getUserAccount,
            getAppContext: () => {
                return this.state.appContext;
            },
            updateAppContext: this.updateAppContext,
            setAppError: this.setAppError,
            clearAppError: this.clearAppError
        };

        var component = null;

        if (this.hasError() === true)
        {
            component = this.renderError();
        }
        else
        {
            if (this.state.isAuthenticated === true)
            {
                component = this.props.render({
                    config: this.props.config,
                    getUserAccount: this.getUserAccount,
                    getAppContext: () => {
                        return this.state.appContext;
                    }
                });
                
                component = React.cloneElement(component, { appService: appService });
            }
            else
            {
                component = this.renderLoading();
            }
        }

        return (
            <AppServiceContext.Provider value={appService}>
                {component}
            </AppServiceContext.Provider>
        );
    }

    componentDidCatch(error, errorInfo) 
    {
        console.error("Error caught in react component", { error: error, errorInfo: errorInfo });

        this.setState(function (state) {
            var newAppContext = {
                ...state.appContext,
                error: error
            };

            return {
                appContext: newAppContext
            };
        });
    }

    async componentDidMount()
    {
        await this.msal.handleRedirectPromise().catch((error) => {
            console.error(error);
        });

        var isAuthenticated = await this.checkIfAuthenticated();

        var appContext = null;
        if (isAuthenticated === true)
        {
            appContext = await this.props.onAppContextInitializing({
                config: this.props.config,
                getUserAccount: this.getUserAccount,
                acquireToken: this.acquireToken,
                updateAppContext: this.updateAppContext,
                setAppError: this.setAppError
            });
        }

        this.setState(function (state) {
            var newAppContext = {
                ...state.appContext,
                ...appContext
            };

            return {
                isAuthenticated: isAuthenticated,
                appContext: newAppContext
            };
        }, function ()
        {
            if (this.state.isAuthenticated === false)
            {
                var automaticLogin = true;
                if (('preventAutomaticLogin' in this.props.config) &&
                    (this.props.config.preventAutomaticLogin === true))
                {
                    automaticLogin = false;
                }

                if (automaticLogin === true)
                {
                    // Redirects for login.
                    this.login();
                }
            }
            else
            {
                // Enables auth state polling intervally after the app is authenticated.
                // Default polling time is 5 minutes.
                var authStatePollingIntervalMins = 5;
                if (('authStatePollingIntervalMins' in this.props.config) &&
                    (typeof(this.props.config.authStatePollingIntervalMins) === 'number'))
                {
                    authStatePollingIntervalMins = this.props.config.authStatePollingIntervalMins;
                }
                
                this.enableAuthStatePolling(authStatePollingIntervalMins * (60 * second));
            }
        });

        return;
    }

    componentWillUnmount()
    {
        this.disableAuthStatePolling();
        return;
    }

    handleAuthRedirectCallback(error, response)
    {
        if (error != null)
        {
            // Handles error.
            throw new Error(`OpenID Connect client redirect error - ${JSON.stringify(error)}`);
        }
        return;
    }

    enableAuthStatePolling(interval)
    {
        var self = this;

        this.authStatePollingTimerId = setInterval(async function () {

            var isAuthenticated = await self.checkIfAuthenticated();

            if (isAuthenticated === false)
            {
                // Updates component state to unauthenticated.
                self.setState({
                    isAuthenticated: isAuthenticated
                }, function ()
                {
                    if (self.state.isAuthenticated === false)
                    {
                        var automaticLogin = true;
                        if (('preventAutomaticLogin' in self.props.config) &&
                            (self.props.config.preventAutomaticLogin === true))
                        {
                            automaticLogin = false;
                        }

                        if (automaticLogin === true)
                        {
                            // Redirect for login.
                            self.login();
                        }
                    }
                });
            }
        }, interval);

        return;
    }

    disableAuthStatePolling()
    {
        clearInterval(this.authStatePollingTimerId);
        return;
    }

    async checkIfAuthenticated()
    {
        var isAuthenticated = false;
        var account = await this.getUserAccount();

        if (account != null)
        {
            try
            {
                // Attempts to silently exchange a token with basic login scope.
                var tokenResponse = await this.acquireToken({
                    account: account,
                    scopes: this.loginScopes
                }, false);

                if (tokenResponse != null)
                {
                    isAuthenticated = true;
                }
            }
            catch (error)
            {
                // Fails to exchange token for any reason. Current state is
                // therefore deemed as unauthenticated.
            }
        }

        return isAuthenticated;
    }

    getUserAccount()
    {
        var userAccount = null;
        var userAccounts = this.msal.getAllAccounts();
        if ((userAccounts != null) &&
            (userAccounts instanceof Array) &&
            (userAccounts.length > 0))
        {
            userAccount = userAccounts[0];
        }

        return userAccount;
    }

    login(request)
    {
        var scopes = this.loginScopes.concat([]);
        if (request != null)
        {
            if ('scopes' in request)
            {
                scopes = scopes.concat(request.scopes);
            }
        }

        let redirectCallbackPath = Url(this.props.config.auth.redirectUri).components().path;
        if (redirectCallbackPath[0] !== "/")
        {
            redirectCallbackPath = "/" + redirectCallbackPath;
        }

        if (window.location.pathname !== redirectCallbackPath) {
            this.msal.loginRedirect({
                scopes: scopes
            });
        }

        return;
    }

    logout()
    {
        this.msal.logout();
        return;
    }

    async acquireToken(request, handleMsalError)
    {
        if (handleMsalError == null)
        {
            handleMsalError = true;
        }

        if (request.account == null) {
            request.account = await this.getUserAccount();
        }

        var response = null;
        try
        {
            // If a token is not present in the cache, it will acquire the token by calling the OIDC 
            // authorization endpoint via a hidden iframe and cache it. If a token is present
            // in the cache but is almost expiring, it will renew for a new token via a hidden 
            // iframe. This is achieved via the OIDC silent authentication (authorization endpoint 
            // with prompt=none).
            var silentRedirectUri = this.props.config.auth.silentRedirectUri;
            var req = Object.assign({}, { redirectUri: silentRedirectUri }, request);
            response = await this.msal.acquireTokenSilent(req);
        }
        catch (error)
        {
            if (handleMsalError === false)
            {
                throw error;
            }
            else
            {
                if (error instanceof Msal.InteractionRequiredAuthError)
                {
                    this.msal.acquireTokenRedirect(request);
                    throw new AuthError.InteractionRequiredAuthError(error);
                }
                else
                {
                    throw error;
                }
            }
        }

        return response;
    }

    updateAppContext(appContext)
    {
        this.setState(function (state) {
            var newAppContext = {
                ...state.appContext,
                ...appContext
            };

            return {
                appContext: newAppContext
            };
        });

        return;
    }

    setAppError(e)
    {
        var error = null;

        if (typeof (e) === 'string')
        {
            error = new Error(e);
        }
        else
        {
            error = e;
        }

        this.setState(function (state) {
            var newAppContext = {
                ...state.appContext,
                error: error
            };

            return {
                appContext: newAppContext
            };
        });

        return;
    }

    clearAppError()
    {
        this.setState(function (state) {
            var newAppContext = {
                ...state.appContext,
                error: null
            };

            return {
                appContext: newAppContext
            };
        });
    }

    hasError()
    {
        var hasError = false;

        if ((this.state.appContext == null) ||
            (this.state.appContext.error != null))
        {
            hasError = true;
        }

        return hasError;
    }

    renderLoading()
    {
        var loader = null;

        if (this.props.renderLoading != null)
        {
            loader = this.props.renderLoading({
                config: this.props.config,
                onLogin: this.login
            });
        }
        else
        {
            loader = <LoadingSplashScreen config={this.props.config} onLogin={this.login} />;
        }

        return loader;
    }

    renderError()
    {
        var errorDisplay = null;

        if ((this.state.appContext != null) &&
            (this.state.appContext.error != null))
        {
            if (this.props.renderError != null)
            {
                errorDisplay = this.props.renderError({
                    error: this.state.appContext.error,
                    onLogout: this.logout
                });
            }
            else
            {
                if (this.state.appContext.error instanceof AuthError.InteractionRequiredAuthError)
                {
                    errorDisplay = <AuthInteractionRequiredSplashScreen />;
                }
                else
                {
                    errorDisplay = <ErrorSplashScreen error={this.state.appContext.error} onLogout={this.logout}/>;
                }
            }
        }

        return errorDisplay;
    }
}

function withAppService(Component)
{
    var AppServiceWrapper = class extends React.Component
    {
        render()
        {
            if (this.context == null)
            {
                throw new Error('Fail to provide app service because the component is not a descendant of ProtectedApp component');
            }

            return (
                <Component {...this.props} appService={this.context}/>
            );
        }
    };
    AppServiceWrapper.contextType = AppServiceContext;

    return AppServiceWrapper;
}

var AuthError = {
    InteractionRequiredAuthError: class 
    {
        constructor(innerError)
        {
            this.innerError = innerError
        }
    }
};

export { ProtectedApp, withAppService, AuthError };