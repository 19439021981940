class AppError extends Error
{
    constructor(message)
    {
        super(message);

        this.status = null;
        this.title = message;
        this.detail = null;
    }
}
AppError.create = function (title, status, detail)
{
    let appError = new AppError(title);
    appError.status = status;
    appError.detail = detail;
    return appError;
};

export { AppError };