import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Menu, Icon, Responsive } from 'semantic-ui-react';
import { LayoutContainer } from './LayoutContainer.js';
import { ContextMenu } from './ContextMenu.js';
import { UserCard } from './UserCard.js';
import Avatar from 'react-avatar';
import './TopMenu.css';

class TopMenu extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleMoreMenuLinkClick = this.handleMoreMenuLinkClick.bind(this);
        this.handleSupportMenuLinkClick = this.handleSupportMenuLinkClick.bind(this);
        this.renderSideMenu = this.renderSideMenu.bind(this);
        this.renderSupport = this.renderSupport.bind(this);
        this.renderAvatar = this.renderAvatar.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
        this.countChildren = this.countChildren.bind(this);
        this.renderAppsPanelLink = this.renderAppsPanelLink.bind(this);

        this.state = {
            showMoreMenu: false
        };
    }

    render()
    {
        var menu = (
            <React.Fragment>
                <Menu borderless inverted icon='labeled' className={clsx('main-menu',
                    {'no-bottom-margin': this.state.showMoreMenu})}>
                    
                        {this.renderSideMenu()}
                
                        <Responsive minWidth={Responsive.onlyTablet.minWidth}
                            className={clsx('title', {'hidden': !this.props.menuCollapsed})}>
                            {this.props.title}
                        </Responsive>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}
                            className={clsx('title', 'centered', {'hidden': !this.props.menuCollapsed})}>
                            {this.props.title}
                        </Responsive>

                        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={() => 
                            <Menu.Menu position='right'>
                                {this.renderChildren()}
                                {this.renderSupport()}
                                {this.renderAppsPanelLink()}
                                {this.renderAvatar()}
                            </Menu.Menu>} 
                        />

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={() => 
                            <Menu.Menu position='right' >
                                {
                                    (this.countChildren() > 1 ? 
                                        <Menu.Item link onClick={this.handleMoreMenuLinkClick}>
                                            <Icon link name='ellipsis horizontal'/>
                                            <span className='text'>More</span>
                                        </Menu.Item> 
                                        :
                                        <React.Fragment>
                                            {this.renderSupport()}
                                            {this.renderAppsPanelLink()}
                                            {this.renderAvatar()}
                                        </React.Fragment>
                                    )
                                }
                            </Menu.Menu>} 
                        />
                </Menu>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={() => 
                    <Menu borderless inverted icon='labeled' className={clsx('more-menu',
                    {'hidden': !this.state.showMoreMenu})} size='mini'>
                        <div className='menu-container'>
                            {this.renderChildren()}
                            {this.renderSupport()}
                            {this.renderAppsPanelLink()}
                            {this.renderAvatar()}
                        </div>
                    </Menu>}
                />
            </React.Fragment>
        );


        return (
            <div className='appkit-top-menu'>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <LayoutContainer className='menu-container'>
                        {menu}
                    </LayoutContainer>
                </Responsive>

                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    {menu}
                </Responsive>
            </div>
        );
    }

    renderSideMenu()
    {
        // Default side menu is a dummy placeholder.
        var sideMenu = (
            <React.Fragment>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={Menu.Item} link disabled/>
                <Responsive minWidth={Responsive.onlyTablet.minWidth} as='div' />
            </React.Fragment>
        );

        if (('sideMenu' in this.props) && 
            (this.props.sideMenu !== false))
        {
            sideMenu = (
                <React.Fragment>
                    <Menu.Item link onClick={this.props.onSideMenuLinkClick}>
                        <Icon name='align left'/>
                        <span className='text'>Menu</span>
                    </Menu.Item>
                    
                    {/*<Responsive 
                        maxWidth={Responsive.onlyMobile.maxWidth} 
                        as={Menu.Item} link onClick={this.props.onSideMenuLinkClick}>
                            <Icon name='align left'/>
                            <span className='text'>Menu</span>
                    </Responsive>*/}
                </React.Fragment>
            );
        }

        return sideMenu;
    }

    renderAppsPanelLink()
    {
        var appsMenuItem = null;

        if (("appsPanel" in this.props) &&
            (this.props.appsPanel !== false))
        {
            appsMenuItem = 
                <Menu.Item link onClick={this.props.onAppsPanelLinkClick}>
                    <Icon link name='cubes'/>
                    <span className='text'>Apps</span>
                </Menu.Item>;
        }

        return appsMenuItem;
    }

    renderSupport()
    {
        var supportElement = null;

        if (typeof (this.props.support) === 'function')
        {
            supportElement = 
                <Menu.Item link onClick={this.handleSupportMenuLinkClick}>
                    <Icon link name='call'/>
                    <span className='text'>Support</span>
                </Menu.Item>;
        }

        return supportElement;
    }

    renderAvatar()
    {
        var avatarElement = null;

        if (("avatar" in this.props) &&
            (this.props.avatar !== false))
        {
            avatarElement = 
                <ContextMenu
                    className='appkit-top-menu-user'
                    trigger={
                        <Menu.Item link>
                            <Avatar name={this.props.user.name} size='2.3em' round />
                        </Menu.Item>
                    }
                    position='bottom right'
                    offset='-8, 0'                    
                >
                    <ContextMenu.Item className='user-card'>
                        <UserCard name={this.props.user.name} loginId={this.props.user.loginId} avatarSize='3em' />
                    </ContextMenu.Item>
                    <ContextMenu.Divider />
                    <ContextMenu.Item icon='sign out' text='Sign Out' onClick={this.props.onSignOutLinkClick}/>
                </ContextMenu>
        }

        return avatarElement;
    }

    renderChildren()
    {
        if ((this.props.menuItems != null) &&
            (this.props.menuItems.length > 0))
        {
            return this.props.menuItems.map((menuItem) => 
                <TopMenu.Link 
                    key={menuItem.id} 
                    id={menuItem.id} 
                    icon={menuItem.icon}
                    text={menuItem.text}
                    value={menuItem.value}
                    visible={menuItem.visible}
                    onLinkClick={this.props.onLinkClick}/>
            );
        }
        else
        {
            return this.props.children;
        }
    }

    countChildren()
    {
        var count = 0;

        if ((this.props.menuItems != null) &&
            (this.props.menuItems.length > 0))
        {
            count = this.props.menuItems.length;
        }
        else
        {
            count = React.Children.count(this.props.children);
        }
        
        if (this.renderSupport() != null)
        {
            count += 1;
        }
        if (this.renderAppsPanelLink() != null)
        {
            count += 1;
        }
        if (this.renderAvatar() != null)
        {
            count += 1;
        }

        return count;
    }

    handleMoreMenuLinkClick()
    {
        this.setState(function (state) {
            return {
                showMoreMenu: !state.showMoreMenu
            };
        });
        return;
    }

    handleSupportMenuLinkClick()
    {

    }
}
TopMenu.propTypes = {
    title: PropTypes.string,
    menuItems: PropTypes.array,
    menuCollapsed: PropTypes.bool,
    onSignOutLinkClick: PropTypes.func,
    onSideMenuLinkClick: PropTypes.func,
    onAppsPanelLinkClick: PropTypes.func,
    onLinkClick: PropTypes.func,
    user: PropTypes.shape({
        name: PropTypes.string,
        loginId: PropTypes.string
    }),
    avatar: PropTypes.bool,
    sideMenu: PropTypes.bool,
    support: PropTypes.func
};
TopMenu.defaultProps = {
    user: { name: '', loginId: '' }
};

TopMenu.Link = class extends React.Component
{
    constructor(props)
    {
        super(props);

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    render()
    {
        if (this.props.visible === false)
        {
            return null;
        }
        
        return (
            <Menu.Item link onClick={this.handleLinkClick}>
                <Icon link name={this.props.icon}/>
                <span className='text'>{this.props.text}</span>
            </Menu.Item>
        );
    }

    handleLinkClick()
    {
        if (this.props.onLinkClick != null)
        {
            this.props.onLinkClick({
                id: this.props.id,
                text: this.props.text,
                value: this.props.value
            });
        }

        return;
    }
}
TopMenu.Link.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onLinkClick: PropTypes.func
};

export { TopMenu };