import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import './LayoutContainer.css';

function LayoutContainer(props)
{
    return (
        <React.Fragment>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as='div'
                className={clsx('appkit-layout-container', 'mobile', props.className)}>
                {props.children}
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth} as='div'
                className={clsx('appkit-layout-container', props.className)}>
                {props.children}
            </Responsive>
        </React.Fragment>
    );
}

LayoutContainer.propTypes = {
    className: PropTypes.string
};

export { LayoutContainer };