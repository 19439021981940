import React from 'react';
import { Switch, Route } from "react-router-dom";
import { SkeletonUi } from '../app-kit/ui';
import { NotFoundSplashScreen } from '../splashscreen';
import { AppDashboard } from '../dashboard/AppDashboard.js';
import './App.css';

class App extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            topMenu: { menuItems: [] }
        };

        this.handleTopMenuLinkClick = this.handleTopMenuLinkClick.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.handleDashboardAppClick = this.handleDashboardAppClick.bind(this);
    }

    render()
    {
        var appContext = this.props.appService.getAppContext();
        var component = null;

        if (appContext != null)
        {
            component = <SkeletonUi
                app={appContext.app}
                user={appContext.user}
                topMenu={this.state.topMenu}
                onTopMenuLinkClick={this.handleTopMenuLinkClick}
                onSignOut={this.handleSignOut}
                loading={appContext.loading}
                loadingText={appContext.loadingText}
            >
                <Switch>
                    {/* Default route */}
                    <Route exact path='/' render={(props) => <AppDashboard apps={appContext.user.apps} onAppClick={this.handleDashboardAppClick}/>}/>

                    {/* Catch all route - Showing 404 for all unhandled routes */}
                    <Route component={NotFoundSplashScreen} />
                </Switch>
            </SkeletonUi>;
        }
        else
        {
            throw new Error("AppContext is unexpectedly null");
        }

        return component;
    }

    // Event fired when any custom added top manu bar item is clicked.
    handleTopMenuLinkClick(e)
    {
        // Set e.handled to true to notify the application that you'll handle the event yourself.
        // By default (when e.handled is false), the application will navigate to the link.
        e.handled = true;
    }

    // Event fired when any app in the dashboard is clicked.
    handleDashboardAppClick(e)
    {
        // Navigate to the selected app. You can add in necessary code before navigating to the selected app.
        window.open(e.uri, '_self');
    }

    // Event fired when "Sign Out" top menu bar is clicked.
    handleSignOut()
    {
        // Sign out from application. You can add in necessary clean up code before appService.logout if required.
        var { appService } = this.props;
        appService.logout();
        return;
    }
}

export default App;