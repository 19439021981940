import React from 'react';
import { SplashScreen } from './SplashScreen.js';
import { Icon } from 'semantic-ui-react';
import './AuthInteractionRequiredSplashScreen.css';

function AuthInteractionRequiredSplashScreen(props)
{
    return (
        <SplashScreen className='appkit-auth-interaction-required-splash-screen' text={{
            title: "Permission consent required",
            subtitle: 
                <div>
                    <p className='subtitle'>Redirecting you to permission consent page...</p>
                </div>}}
            icon={() => {
                return (
                    <Icon.Group size='massive'>
                        <Icon size='large' name='shield' color='black' />
                    </Icon.Group>
                );
            }}
        />
    );
}

export { AuthInteractionRequiredSplashScreen };