import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from 'semantic-ui-react';
import { LayoutContainer } from '../app-kit/ui';
import { v4 as uuidv4 } from 'uuid';
import './AppDashboard.css';

function AppDashboard(props)
{
    return (
        <LayoutContainer>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <div className={clsx('app-dashboard', 
                            (props.className != null ? props.className : ""))}>
                            <div className='applist-area'>
                                <AppList apps={props.apps} onAppClick={props.onAppClick}/>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </LayoutContainer>
    );
}
AppDashboard.propTypes = {
    className: PropTypes.string,
    apps: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string,
        uri: PropTypes.string.isRequired,
        enabled: PropTypes.bool,
        visible: PropTypes.bool,
        ordinal: PropTypes.number.isRequired
    })),
    onAppClick: PropTypes.func
};

function AppList(props) 
{
    var size = 4;
    var appItems = null;

    if ((props.apps instanceof Array) &&
        (props.apps.length > 0))
    {
        var visibleAppItems = props.apps.map((appItem) => (appItem.visible === true ? appItem : null));

        if ((visibleAppItems != null) &&
            (visibleAppItems.length > 0))
        {
            appItems = [];

            var requireAdjustment = ((visibleAppItems.length % size) > 0 ? true : false);
            var x = parseInt(visibleAppItems.length / size);
            if (requireAdjustment === true)
            {
                x += 1;
            }

            var n = 0;
            for (let set = 1; set <= x; set++)
            {
                for (let count = 1; count <= size; count++)
                {
                    if (n <= (visibleAppItems.length - 1))
                    {
                        let appItem = visibleAppItems[n];
                        appItems.push(
                            <Grid.Column key={appItem.id}  width={4}>
                                <AppList.Item
                                    id={appItem.id} 
                                    displayName={appItem.displayName} 
                                    icon={appItem.icon}
                                    uri={appItem.uri} 
                                    description={appItem.description} 
                                    onClick={props.onAppClick}
                                    enabled={appItem.enabled}/> 
                            </Grid.Column>
                        );
                    }
                    else
                    {
                        appItems.push(
                            <Grid.Column width={4} key={uuidv4()}>
                                <AppList.ItemPlaceholder />
                            </Grid.Column>
                        );
                    }
                    n++;
                }
            }
        }
    }

    return (
        <div className='app-list'>
            <div className='title'>Applications</div>
            <Grid columns={4}>
                {appItems}
            </Grid>
        </div>
    );
}
AppList.propTypes = {
    apps: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string,
        uri: PropTypes.string.isRequired,
        enabled: PropTypes.bool,
        visible: PropTypes.bool,
        ordinal: PropTypes.number.isRequired
    })),
    onAppClick: PropTypes.func
};

AppList.Item = class extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.handleAppItemClick = this.handleAppItemClick.bind(this);
    }

    render()
    {
        var icon = <i aria-hidden="true" className='blue large icon cube'/>;

        if ((this.props.icon != null) &&
            (typeof (this.props.icon) === 'string'))
        {
            var svgString = encodeURIComponent(this.props.icon);
            var dataUri = `url("data:image/svg+xml,${svgString}")`;
            icon = 
                <i aria-hidden="true" className='icon' style={{
                    background: dataUri
                }}/>
        }

        return (
            <div className={clsx("app-item", (this.props.enabled === false ? 'disabled' : ''))} onClick={this.handleAppItemClick}>
                <div className='app-icon'>
                    {icon}
                </div>
                <div className='app-name'>{this.props.displayName}</div>
                {(this.props.enabled === false ? <div className='subtitle'>Coming soon</div> : null)}
            </div>
        );
    }

    handleAppItemClick()
    {
        if ((this.props.enabled !== false) &&
            (this.props.onClick != null))
        {
            this.props.onClick({
                id: this.props.id,
                displayName: this.props.displayName,
                description: this.props.description,
                uri: this.props.uri
            });
        }
    }
}
AppList.Item.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    uri: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    visible: PropTypes.bool,
    onClick: PropTypes.func
};


AppList.ItemPlaceholder = function ()
{
    return (
        <div className="app-item"></div>
    );
}

export { AppDashboard };