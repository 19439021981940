import { Url } from '../app-kit/utils';
import { RequestService } from '../app-kit/services';

class UserService extends RequestService
{
    constructor(acquireToken)
    {
        super(acquireToken);

        var urlComponents = Url(window.location.href).components();
        var hostUri = `${urlComponents.protocol}://${urlComponents.host}`;

        this.baseUri = (process.env.REACT_APP_API_BASEURI.toLowerCase() === "window.location" ? hostUri : process.env.REACT_APP_API_BASEURI);
        this.id = process.env.REACT_APP_API_ID;
    }

    async getUserByUserAccount(userAccount)
    {
        var appUser = null;

        /*var userProfile = await this.get(`${this.baseUri}/v1/users?loginid=${encodeURIComponent(userAccount.username)}`, {
            scopes: [
                `api://${this.id}/Data.ReadWrite.All`
            ]
        });*/

        var userProfile = await this.get(`${this.baseUri}/v1/me`, {
            scopes: [
                `api://${this.id}/Data.ReadWrite.All`
            ]
        });

        if ((userProfile != null) && 
            (userProfile.id))
        {
            var apps = await this.get(`${this.baseUri}/v1/users/${encodeURIComponent(userProfile.id)}/apps`, {
                scopes: [
                    `api://${this.id}/Data.ReadWrite.All`
                ]
            });
            
            appUser = {
                profile: userProfile,
                name: userProfile.preferredName,
                loginId: userProfile.email,
                apps: apps
            };

            console.log("UserService (getUserByUserAccount", appUser);
        }

        return appUser;
    }
}

export default UserService;
