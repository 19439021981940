import React from 'react';
import { SplashScreen } from '../app-kit/ui';
import { Header } from 'semantic-ui-react';
import './NotFoundSplashScreen.css';

function NotFoundSplashScreen()
{
    return (
        <div className='notfound-splash-screen'>
            <SplashScreen text={{
                title: 'Missing resource',
                subtitle: <div>Sorry, we're unable to find anything on this url.</div>}}
                icon={() => <Header className='text-icon'>404</Header>}
            />
        </div>
    );
}

export default NotFoundSplashScreen;